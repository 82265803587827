import { Socket } from "phoenix";
import store from "../store";

class BagelSocket {
  async setUpChannel() {
     const token = store.getters.getToken;
    // const site = localStorage.getItem("site");
    const companyID = store.getters.getCompanyID;
    // const station = localStorage.getItem("station");
    const kdsBackendUrl = "wss://" + process.env.VUE_APP_API_HOST + "/socket";

    let socket = store.getters.getSocket;
    let channel = store.getters.getChannel;

    if (!store.getters.socketConnected) {
      socket = new Socket(kdsBackendUrl, {
        params: { guardian_token: token },
      });

      store.dispatch("socketAction", this.socket);

      console.log("Attempt to connect to socket with authentication");
      if (token && companyID) {
        //console.log('Ready to connect socket....')

        socket.connect();

        socket.onError((error) => {
          console.log("Socket error: ", error);
        });
        if (channel) channel.leave();
        
        channel = socket.channel("web:" + companyID + ":" + token, {});
      
        store.dispatch("channelAction", channel);

        channel
          .join()
          .receive("ok", (resp) => {
            store.dispatch("socketConnectedAction", true);
            console.log("Joined successfully", resp);
          })
          .receive("error", (resp) => {
            store.dispatch("socketConnectedAction", false);
            console.log("Unable to join", resp);
            //app.router.push('/station');
          })
          .receive("timeout", (resp) => {
            store.dispatch("socketConnectedAction", false);
            console.log("Timeout", resp);
          });
      }
    }
  }
}

const bagelSocket = new BagelSocket();
export { bagelSocket };
