import { createStore } from "vuex";

export default createStore({
  state: {
    companyID: "",
    token: false,
    socketConnected: false,
    socket: null,
    channel: null,
  },
  getters: {
    getCompanyID(state) {
      return state.companyID;
    },
    getToken(state) {
      return state.token;
    },
    socketConnected(state) {
      return state.socketConnected;
    },
    getSocket(state) {
      return state.socket;
    },
    getChannel(state) {
      return state.channel;
    }
  },
  mutations: {
    companyIDMutation(state, payload) {
      state.companyID = payload;
    },
    tokenMutation(state, payload) {
      state.token = payload;
    },
    socketConnectedMutation(state, payload) {
      state.socketConnected = payload;
    },
    socketMutation(state, payload) {
      state.socket = payload;
    },
    channelMutation(state, payload) {
      state.channel = payload;
    }
  },
  actions: {
    companyIDAction({ commit }, payload) {
      commit("companyIDMutation", payload);
    },
    tokenAction({ commit }, payload) {
      commit("tokenMutation", payload);
    },
    socketConnectedAction({ commit }, payload) {
      commit("socketConnectedMutation", payload);
    },
    socketAction({ commit }, payload) {
      commit("socketMutation", payload);
    },
    channelAction({ commit }, payload) {
      commit("channelMutation", payload);
    }
  },
  modules: {},
});
