<template>
    <div></div>
</template>

<script>
export default {
    name: 'PaymentComplete',
    // Add your component logic here
}
</script>


<style scoped>


</style>