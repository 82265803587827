<template>
  <div v-if="paymentProccessing" class="payment-proccessing">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 150">
      <path
        fill="none"
        stroke="#007bff"
        stroke-width="5"
        stroke-linecap="round"
        stroke-dasharray="300 385"
        stroke-dashoffset="0"
        d="M275 75c0 31-27 50-50 50-58 0-92-100-150-100-28 0-50 22-50 50s23 50 50 50c58 0 92-100 150-100 24 0 50 19 50 50Z">
        <animate attributeName="stroke-dashoffset" calcMode="spline" dur="2" values="685;-685" keySplines="0 0 1 1" repeatCount="indefinite"></animate>
      </path>
    </svg>
  </div>
  <div v-if="home" class="voucher-purchase">
    <h2 class="voucher-company">{{ voucher.company.name }}</h2>
    <form class="purchase-form">
      <label for="amount">Voucher Amount</label>
      <input type="number" id="amount" name="amount" min="0" v-model="voucher.amount" required />
      <label for="your-email">Purchaser's Email</label>
      <input type="email" id="your-email" name="your-email" v-model="voucher.purchaserEmail" required />
      <p>This will send the reciept {{ showSomeoneElseSection ? "" : "and digital voucher" }} to the Purchaser's email.</p>
      <div>
        <p>Total includes stripe credit card fee: ${{ stripeTotal }}</p>
      </div>
      <button class="someone-else-button" @click.stop.prevent="toggleSomeoneElseSection">{{ showSomeoneElseSection ? "Send to Purchaser" : "Send As A Gift" }}</button>
      <div v-if="showSomeoneElseSection">
        <p>This will send the digital voucher to the recipient's email.</p>
        <label for="recipient-email">Recipient's Email</label>
        <input type="email" id="recipient-email" name="recipient-email" v-model="voucher.recipientEmail" required />
        <label for="message">Message (optional)</label>
        <textarea id="message" v-model="voucher.message" name="message"></textarea>
      </div>

      <div v-if="errors.length">
        <h3 class="errors" v-for="(error, idx) in errors" :key="idx + error">{{ error }}</h3>
      </div>

      <button type="submit" @click.stop.prevent="submit">Continue to Payment</button>
    </form>
  </div>
  <div v-if="finished">
    <h1>Payment Complete</h1>
    <p>Thank you for your payment!</p>
    <p>The voucher & receipt have been sent to your selected emails.</p>
    <p class="button" @click.stop.prevent="goHome">Purchase Another Voucher</p>
    <iframe :src="'https://flaskapi-gpvubq4nta-ts.a.run.app/receipt/' + company + '/' + site + '/' + voucherID" frameborder="0" width="1200" height="880"></iframe>
    <!-- Add your payment complete content here -->
  </div>
  <div class="payment-section">
    <form @submit.prevent="handlePayment" id="payment-form">
      <div id="payment-element">
        <!--Stripe.js injects the Payment Element-->
      </div>
      <button v-if="intentLoaded" id="submit">
        <div class="spinner hidden" id="spinner"></div>
        <span id="button-text">Pay now</span>
      </button>
      <div id="payment-message" class="hidden"></div>
    </form>
  </div>
</template>

<script>
import { api } from "@/helpers/api";
import { bagelSocket } from "@/helpers/socket";
import { customAlphabet } from "nanoid";

export default {
  name: "VoucherPurchase",
  data() {
    return {
      stripe: null,
      elements: null,
      intentLoaded: false,
      paymentProccessing: false,
      finished: false,
      home: true,
      voucherID: false,
      voucher: {
        company: false,
        purchaserEmail: "",
        recipientEmail: "",
        message: "",
        amount: 0,
      },
      showSomeoneElseSection: false,
      errors: [],

      alphabet: "1234567890abcdefghijklmnopqrstuvwxyz",
    };
  },
  components: {},
  methods: {
    goHome() {
      location.reload();
    },

    toggleSomeoneElseSection() {
      this.showSomeoneElseSection = !this.showSomeoneElseSection;
    },
    async submit() {
      this.errors = [];
      if (this.voucher.purchaserEmail.length === 0 || this.voucher.amount === 0) {
        this.errors.push("Please enter a purchaser email and amount");
      }
      if (this.showSomeoneElseSection && this.voucher.recipientEmail.length === 0) {
        this.errors.push("Please enter a recipient email");
      }

      let order_data = {
        amount: parseInt(this.stripeTotal * 100), // add 3% for stripe fee
        currency: "nzd",
      };

      if (this.errors.length === 0) {
        this.$store.getters.getChannel.push("stripe:paymentIntent", { order: order_data }).receive("ok", (intent) => this.updateIntent(intent.reply));
      }
    },

    updateIntent(clientSecret) {
      const appearance = {
        theme: "stripe",
        variables: {
          colorPrimary: "#CC247B",
          colorBackground: "#fafaff",
          colorText: "#3B3C36",
        },
      };

      const paymentElementOptions = {
        layout: "tabs",
      };
      this.elements = this.stripe.elements({ appearance, clientSecret });
      this.intentLoaded = true;
      console.log("intent received");
      const paymentElement = this.elements.create("payment", paymentElementOptions);
      paymentElement.mount(document.querySelector("#payment-element"));
    },

    async handlePayment() {
      document.querySelector(".payment-section").style.setProperty("display", "none");
      this.paymentProccessing = true;
      let elements = this.elements;
      // Confirm the payment
      const result = await this.stripe.confirmPayment({
        elements: elements,
        redirect: "if_required",
        confirmParams: {
          return_url: window.location.href,
          receipt_email: this.email,
        },
      });
      if (result.error) {
        console.log(result.error.message);
        this.paymentProccessing = false;
        document.querySelector(".payment-section").style.setProperty("display", "block");
        this.errors.push(result.error.message);
      } else {
        if (result.paymentIntent.status === "succeeded") {
          console.log("payment succeeded");
          this.completeTransaction(result);
        }
      }
    },

    completeTransaction(result) {
      console.log("complete transaction");
      const nanoid = customAlphabet(this.alphabet, 6);
      let voucher = {
        ID: nanoid(),
        name: this.showSomeoneElseSection ? this.voucher.recipientEmail : this.voucher.purchaserEmail,
        doc_type: "credit",
        value: this.voucher.amount,
        date: new Date().toISOString(),
      };

      let voucher_order = {
        total: result.paymentIntent.amount / 100,
        events: {
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
        },
        paymentStatus: "paid",
        payments: [
          {
            type: "stripe",
            value: result.paymentIntent.amount / 100,
            stripeID: result.paymentIntent.id,
          },
        ],
        products: [
          {
            name: "Voucher",
            price: this.voucher.amount,
            quantity: 1,
            voucher: voucher.ID,
          },
          {
            name: "Stripe Fee",
            price: this.voucher.amount * 0.03,
            quantity: 1,
          },
        ],
      };

      this.$store.getters.getChannel.push("voucher:doc", voucher);
      voucher.msg = this.voucher.message;
      if (this.voucher.recipientEmail) {
        voucher.sender = this.voucher.purchaserEmail;
      }
      this.$store.getters.getChannel.push("email:voucher", voucher);
      this.$store.getters.getChannel.push("voucherorder:new", voucher_order).receive("ok", (reply) => {
        console.log(reply);
        reply.reply.email = this.voucher.purchaserEmail;
        this.$store.getters.getChannel.push("email:voucherreceipt", reply.reply);
        this.finished = true;
        this.paymentProccessing = false;
        this.home = false;
        this.voucherID = reply.reply._id;
      });
    },
  },

  async mounted() {
    const stripeKey = process.env.VUE_APP_STRIPE_LIVE;
    // estlint-disable-next-line
    this.stripe = window.Stripe(stripeKey);
    this.stripe.api_version = '2024-04-10'
  },

  async created() {
    if (this.$route.params.id) {
      const res = await api.getSiteToken(`"${this.$route.params.id}"`);
      if (!("error" in res)) {
        this.$store.dispatch("tokenAction", res.jwt_token);
        this.$store.dispatch("companyIDAction", res.company);
        this.voucher.company = res;
        bagelSocket.setUpChannel();
      } else {
        console.log("send to 404");
      }
    }
  },

  computed: {
    stripeTotal() {
      return this.voucher.amount + this.voucher.amount * 0.03;
    },
    company() {
      return this.$route.params.id.split("-")[0];
    },
    site() {
      return this.$route.params.id.split("-")[1];
    },
  },
};
</script>

<style scoped lang="scss">
.payment-section {
  max-width: 400px;
  margin: auto;
  button {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background-color: #0056b3;
    }
  }
}
iframe {
  transform: scale(0.5);
  transform-origin: top left;
  width: 750px;
}
.button {
  background-color: #007bff;
  max-width: 380px;
  margin: auto;
  color: #fff;
  border-radius: 5px;
  margin-bottom: 2rem;
  cursor: pointer;
  padding: 10px;
}
.button:hover {
  background-color: #0056b3;
}
a {
  text-decoration: none;
}
.payment-proccessing {
  height: 100vh;
  width: 100vw;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.75);
  position: absolute;
  svg {
    width: 15rem;
    margin-top: 15rem;
  }
}
.voucher-purchase {
  top: 0;
  // position: absolute;
  text-align: center;
  padding: 20px;
  margin: 0 auto;
  max-width: 400px;

  label {
    display: block;
    margin-top: 10px;
  }

  input {
    width: 90%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  textarea {
    width: 90%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  button {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background-color: #0056b3;
    }
  }

  .someone-else-button {
    margin-top: 20px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background-color: #555;
    }
  }
  .errors {
    color: red;
  }

  .someone-else-section {
    display: block;
    flex-direction: column;
    align-items: center;
  }

  .showSomeoneElseSection .someone-else-section {
    display: block;
  }
}
</style>
