<template>
  <div class="home">
    <h1>Beamy Vouchers</h1>
    <h2>This is a testing site. Please exit if you aren't supposed to be here!</h2>
    <h2>If you're using localHost, click link below</h2>
    

  </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'HomeView',
  components: {
    
  }
}
</script>
