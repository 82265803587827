import store from "../store";

class API {

    constructor() {
        this.API_URL = "https://" + process.env.VUE_APP_API_HOST;
    }

    async getCompanySite() {
        const site = await window.localStorage.getItem("site");
        const company = await window.localStorage.getItem("company");
        return '/' + company + '/' + site;
    }

    async getUrl(url = '', queryString = '', includeCompany = false) {
        this.token = await window.localStorage.getItem("token");
        this.companyString = await this.getCompanySite();
        let rawUrl = url + ( includeCompany ? this.companyString : '') + queryString
        const response = await fetch(this.API_URL + rawUrl, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.token
            }
        });
        return response.json(); // parses JSON response into native JavaScript objects
    }

    async get(url = '') {
        this.token = await window.localStorage.getItem("token")
        const response = await fetch(this.API_URL + url, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.token
            }
        });
        return response.json(); // parses JSON response into native JavaScript objects
    }

    async post(url = '', data = {}) {
        this.token = store.getters.getToken;
        const response = await fetch(this.API_URL + url, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.token
            },
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        });

        if (response.status != 200 && response.status != 201) {
            throw "Server error: Error code is " + response.status
        }

        return response.json(); // parses JSON response into native JavaScript objects
    }

    async login(email, password, company) {
        let result = await api.post('/api/user/login', {user: email || '', password: password || '', company: company || ''})
        // TODO: Shouldn't respond with this, security weakness.
        if (result === 'user not found') {
            return { email: 'not found' }
        }
        else if ( result === 'unauthorized' ) {
            return { email: 'unauthorized' }
        }
        return result;
    }

    async getSiteToken(siteID) {
        let result = await api.post('/api/voucher/store', {key: siteID})
        return result
    }

    async loadTheme(theme){
        let themes = Object.keys(theme);
        themes.map((colour) => {
            //console.log(theme[colour])
            if(theme[colour]!= null){
                document.querySelector(':root').style.setProperty(colour, theme[colour]);
            }
        })
    }

    async getSite(routeParam) {
      this.errors = {};
      try {
        // await window.localStorage.clear();
        //let result = await api.getSiteToken('"' + this.$route.params.id + '"');
        let result = await api.getSiteToken('"' + routeParam + '"');
        await window.localStorage.setItem("token", result.jwt_token);

        //const company = await api.get('/api/company/get_pos/"' + result.company + '"');
        await window.localStorage.setItem("company", result.company);
        await window.localStorage.setItem("companyName", result.name);
        await window.localStorage.setItem("site", result.site);
        await window.localStorage.setItem("station", result.jwt_token);
        await window.localStorage.setItem("shops", JSON.stringify(result.settings));
        this.loadTheme(result.theme);
        
        // console.log(result);
        // console.log("store", this.$store);
        
        this.$.appContext.config.globalProperties.token = result.jwt_token;
        
        /* set the global JWT token */
        this.$store.dispatch('setToken', result.jwt_token)
        this.$store.commit('setWebStoreMutation', true)
        //this.$router.push({ path: '/store/' + this.$route.params.id });
        //await this.$store.dispatch('getAllData')
      }
      catch(error) {
        Object.keys(error).map(code => {
          this.errors[code] = [error[code]];
        })
        
      }
    }

    async searchAccounts(query) {
        return await api.getUrl("/api/search_accounts", "?group=true&start_key=[\"" + query + "\"]&end_key=[\"" + query + "\ufff0\"]", true)
    }

    async searchVouchers(query) {
        return await api.getUrl("/api/search_vouchers", "?group=true&start_key=[\"" + query + "\"]&end_key=[\"" + query + "\ufff0\"]", true)
    }
  
    async signup(user){
        return await api.post("/api/enduser/signup", user)
    }

    async updateUser(user){
        return await api.post("/api/enduser/update/" + window.localStorage.getItem("company") + "/" + user._id, {doc: user})
    }
    
    async getUserOrders(doc){
        return await api.post("/api/enduser/get_orders/" + window.localStorage.getItem("company") + "_" + window.localStorage.getItem("site") + "_orders", {docs: doc})
    }

    async resetPassword(email){
        return await api.post("/api/enduser/reset", {email: email, db: window.localStorage.getItem("company") + "_users/"})
    }

    async updatePass(token, password){
        return await api.post("/api/enduser/update_pass", {token: token, password: password})
    }
}

const api = new API();
export {api}
