import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import VoucherPurchase from '../components/VoucherPurchase.vue'
import PaymentComplete from '../views/PaymentComplete.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/PaymentComplete/:company/:site/:id',
    name: 'PaymentComplete',
    component: PaymentComplete
  },
  {
    path: '/site/:id',
    name: 'vouchers',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: VoucherPurchase
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
